import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import { CTAPopupOpenContext } from '../../hoc/main-layout';
import ContactForm from '../contact-form/contact-form';

import * as PopupCtaS from './popup-cta.module.scss';
import * as TextS from '../../assets/styles/text.module.scss';

const PopupCta = () => {

    const {popupActive, setPopupActive} = useContext(CTAPopupOpenContext);

    const [projectsLeft, setProjectsLeft] = useState(2);

    useEffect(() => {

        const getData = async () => {
            try{
                const res = await axios.get('https://web-professor.pl/API/get-projects-left.php');
                setProjectsLeft(res.data);
            } catch (err){
            }
        }
        getData();

    }, []);

    return (
        <div className={`${popupActive && PopupCtaS.active} ${PopupCtaS.popup_wrapper}`}>

            <div className={PopupCtaS.shadow} onClick={() => setPopupActive(false)}></div>

            <div className={PopupCtaS.content}>

                <button className={PopupCtaS.close_btn} onClick={() => setPopupActive(false)} />

                <div className={PopupCtaS.before_form}>
                    <h2>Szybka wycena</h2>

                    <div className={`${TextS.subtitle} ${PopupCtaS.subtitle}`}>
                        Opowiedz nam o swoim projekcie i otrzymaj niezobowiązującą ofertę w 24h.
                    </div>

                    <hr className={PopupCtaS.separator}/>

                    <div className={`${TextS.before_title} ${PopupCtaS.before_title}`}>
                        NIE CZEKAJ!
                    </div>

                    <h3>W tym miesiącu jesteśmy w stanie przyjąć jeszcze tylko
                        <span className={TextS.green_highlight}> {projectsLeft} projekty.</span>
                    </h3>
                </div>



                <ContactForm />


            </div>

        </div>
    );

}

export default PopupCta;