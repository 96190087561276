// extracted by mini-css-extract-plugin
export var form_wrapper = "contact-form-module--form_wrapper--1qPtG";
export var response_status = "contact-form-module--response_status--2N9lA";
export var error = "contact-form-module--error--1CzD9";
export var success = "contact-form-module--success--W75oo";
export var form = "contact-form-module--form--5Vq-V";
export var field = "contact-form-module--field--3C-zI";
export var full_width = "contact-form-module--full_width--19whp";
export var privacy_width = "contact-form-module--privacy_width--h5SGj";
export var more_info = "contact-form-module--more_info--1lLYZ";
export var privacy_rules = "contact-form-module--privacy_rules--2osrx";
export var error_text = "contact-form-module--error_text--1KoxI";
export var submit_btn = "contact-form-module--submit_btn--1qWjy";