import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';


import * as HeroS from './hero.module.scss';
import * as TextS from '../../assets/styles/text.module.scss';


const Hero = () => {

    const data = useStaticQuery(graphql`
        query{
            effect_down: file(relativePath: { eq: "images/effect_down.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 100)
                }
            }
            effect_up: file(relativePath: { eq: "images/effect_up.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 100)
                }
            }
        }
    `);

    return (
        <section className={HeroS.hero}>

            <GatsbyImage
                className={HeroS.effect_up}
                image={data.effect_up.childImageSharp.gatsbyImageData}
                alt='background image'
            />

            <GatsbyImage
                className={HeroS.effect_down}
                image={data.effect_down.childImageSharp.gatsbyImageData}
                alt='background image'
            />

            <div className={HeroS.content}>
                <h1>
                    Lepsza strona
                    to<br/>
                    <span className={HeroS.highlight}> lepszy biznes</span>
                </h1>

                {/* <div className={`${TextS.subtitle} ${HeroS.subtitle}`}>
                    Dobra strona internetowa pomoże Ci znacznie szybciej rozwinąć biznes.
                </div> */}

            </div>
        </section>
    )
}

export default Hero;