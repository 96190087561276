import React, { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';

import * as CookiesPopupS from './cookies-popup.module.scss';

const CookiesPopup = () => {

    const [cookie, setCookie] = useCookies(['cookies-accept']);
    const cookies = useRef();

    const scrollHandler = () => {
        cookies.current.style["bottom"] = '-90px';
        setCookie('cookies-accept', true);
        window.removeEventListener('scroll', scrollHandler);
    }

    useEffect(() => {

        if(!cookie['cookies-accept']){
            window.addEventListener("scroll", scrollHandler);
        } else {
            cookies.current.style['display'] = 'none';
        }

    }, [])


    return (
        <div ref={cookies} className={CookiesPopupS.wrapper}>
            Używamy na naszej stronie plików cookies w celu zapewnienie prawidłowego działania serwisu oraz prowadzenia
            danych statystycznych. Dalsze korzystanie z tej strony oznacza akceptację cookies.
        </div>
    );
}

export default CookiesPopup;