import React, { useContext } from 'react';


import { CTAPopupOpenContext } from '../../../hoc/main-layout';

import * as CtaBtnS from './cta.module.scss';

const CtaBtn = ({ children, classes, type = 'button' }) => {

    const {popupActive, setPopupActive} = useContext(CTAPopupOpenContext);

    const handleClick = () => {

        if(type !== 'submit'){
            setPopupActive(true);
        } else {
            window.gtag('event', 'conversion', {'send_to': 'AW-439553411/JYWZCMzy6oADEIObzNEB'});
            console.log('cnv');
        }


    }

    return (
        <button
            onClick={handleClick}
            className={`${CtaBtnS.btn} ${classes}`}
            type={type}
        >

            { children }

        </button>
    )
}

export default CtaBtn;