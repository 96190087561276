import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import CtaBtn from '../../ui-components/buttons/cta/cta';

import * as ContactFormS from './contact-form.module.scss';


const ContactForm = () => {

    const { register, handleSubmit, formState: { errors } } = useForm({mode: 'onChange'});
    const [reqStatus, setReqStatus] = useState('');

    const submitHandler = async (data) => {

        try{
            setReqStatus('pending');

            const res = axios.post('https://web-professor.pl/API/send-contact-form.php', data);

            setReqStatus('done');

        } catch (err) {
            setReqStatus('error');
        }

    }

    return (
        <div className={ContactFormS.form_wrapper}>

            <div className={ContactFormS.response_status}>
                <span className={ContactFormS.error}>
                    {reqStatus === 'error' && 'Wystąpił błąd. Prosimy spróbować ponownie za kilka minut lub przesłać formularz ręcznie na adres: kontakt@web-professor.pl. Przepraszamy za utrudnienia.'}
                </span>
                <span className={ContactFormS.success}>
                    {reqStatus === 'done' && 'Formularz przesłany! Zaczynamy pracę nad przygotowaniem dla Ciebie najkorzystniejszej oferty. Otrzymasz ją na podany w formularzu adres email w ciągu 24h.'}
                </span>
            </div>

            {reqStatus === 'pending' ?
                <div className={ContactFormS.pending}>Wysyłanie...</div>
            :
                <form onSubmit={handleSubmit(submitHandler)} className={`${ContactFormS.form}`}>

                    {/* name */}
                    <div className={`${ContactFormS.field}`}>
                        <label htmlFor='name'>Imię i nazwisko</label>
                        <span className={ContactFormS.error_text}>
                            {errors.name && errors.name.type === 'required' && 'Uzupełnij Imię i nazwisko!'}
                        </span>
                        <input id='name' className={errors.name ? ContactFormS.error : ''} type='text'
                            {...register("name",
                                {
                                    required: true
                                }
                            )}
                        />
                    </div>


                    {/* email */}
                    <div className={`${ContactFormS.field}`}>
                        <label htmlFor='email'>Adres email</label>
                        <span className={ContactFormS.error_text}>
                            {errors.email && errors.email.type === 'required' && 'Uzupełnij adres email!'}
                        </span>
                        <input id='email' className={errors.email ? ContactFormS.error : ''} type='email'
                            {...register("email",
                                {
                                    required: true
                                }
                            )}
                        />
                    </div>


                    {/* tel */}
                    <div className={`${ContactFormS.field}`}>
                        <label htmlFor='tel'>Telefon <span>(Opcjonalnie)</span></label>
                        <input id='tel' className={errors.tel ? ContactFormS.error : ''} type='tel'
                            {...register("tel",
                            )}
                        />
                    </div>


                    {/* time */}
                    <div className={`${ContactFormS.field}`}>
                        <label htmlFor='time'>Termin realizacji</label>

                        <select id='time' className={errors.time ? ContactFormS.error : ''}
                            {...register("time",
                            )}
                        >
                            <option value='kilka tygodni'>Kilka tygodni</option>
                            <option value='jak najszybciej'>Jak najszybciej</option>
                            <option value='kilka miesięcy'>Kilka miesięcy</option>
                        </select>
                    </div>


                    {/* msg */}
                    <div className={`${ContactFormS.field} ${ContactFormS.full_width}`}>
                        <label htmlFor='msg'>Wiadomość</label>
                        <span className={ContactFormS.more_info}>
                            Postaraj się jak najdokładniej opisać swój projekt. Im więcej się dowiemy, tym lepiej Ci pomożemy.
                        </span>
                        <span className={ContactFormS.error_text}>
                            {errors.msg && errors.msg.type === 'required' && 'Uzupełnij wiadomość!'}
                        </span>
                        <textarea id='msg' className={errors.msg ? ContactFormS.error : ''}
                            {...register("msg",
                                {
                                    required: true
                                }
                            )}
                        />
                    </div>


                    {/* privacy rules */}
                    <div className={`${ContactFormS.field} ${ContactFormS.privacy_width}`}>
                        <span className={ContactFormS.privacy_rules}>
                            Wysyłając brief wyrażam zgodę na przetwarzanie podanych w formularzu danych.
                            Zostałem poinformowany, że przysługuje mi prawo dostępu do danych, ich
                            zmiany, usunięcia i zaprzestania przetwarzania. Administratorem danych osobowych jest
                            firma WebProfessor - Rafał Adamski.
                        </span>
                    </div>

                    {reqStatus !== 'done' &&
                        <CtaBtn type="submit"
                            classes={ContactFormS.submit_btn}
                        >Wyślijcie mi ofertę!</CtaBtn>
                    }

                </form>
            }

        </div>
    );
}

export default ContactForm;