// extracted by mini-css-extract-plugin
export var header = "header-module--header--2K2b3";
export var scrolled = "header-module--scrolled--3toAb";
export var main = "header-module--main--2H94S";
export var navContent = "header-module--navContent--3OnW-";
export var logo_link = "header-module--logo_link--BxvDb";
export var logo_green = "header-module--logo_green--8U6T3";
export var logo_purple = "header-module--logo_purple--3rE4b";
export var right_menu = "header-module--right_menu--2orn6";
export var cta_btn = "header-module--cta_btn--1Mh8B";
export var sub = "header-module--sub--2bpZk";
export var social = "header-module--social--2oTcm";
export var icon = "header-module--icon--wxbMk";
export var contact_info = "header-module--contact_info--3PZzD";
export var logo = "header-module--logo--3tWQ2";
export var tel_ico = "header-module--tel_ico---c0M6";