import React, { useEffect, useState } from 'react';
import { Link } from "gatsby";

import * as HeaderS from './header.module.scss';
import CtaBtn from '../../ui-components/buttons/cta/cta';

import LogoGreen2 from '../../assets/images/Logo-green2.svg';
import LogoPurple from '../../assets/images/Logo-purple.svg';
import TelIco from '../../assets/images/tel.svg';
import MailIco from '../../assets/images/mail.svg';
import FbIco from '../../assets/images/fb.svg';
import IgIco from '../../assets/images/ig.svg';
import InIco from '../../assets/images/in.svg';



const Header = () => {

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {

            window.addEventListener("scroll", () => {

                    if (window.scrollY >= 30) {
                        setScrolled(true);
                    } else {
                        setScrolled(false);
                    }
                }
            );

    }, [])

    return (
        <header className={`${HeaderS.header} ${scrolled && HeaderS.scrolled}`}>

            <div className={HeaderS.main}>

                <nav className={HeaderS.navContent}>

                    <Link to='/' className={HeaderS.logo_link}>
                        <img
                            className={`${HeaderS.logo} ${HeaderS.logo_green}`}
                            src={LogoGreen2}
                            alt='WebProfessor logo'/>

                        <img
                            className={`${HeaderS.logo} ${HeaderS.logo_purple}`}
                            src={LogoPurple}
                            alt='WebProfessor logo'/>
                    </Link>


                    <div className={HeaderS.right_menu}>

                        <CtaBtn classes={HeaderS.cta_btn}>Szybka wycena</CtaBtn>


                    </div>

                </nav>

            </div>


            <div className={HeaderS.sub}>

                <nav className={HeaderS.navContent}>

                    <div className={HeaderS.social}>

                        <a href="https://fb.me/webprofessorPL" target="_blank" rel='noopener'>
                            <img src={FbIco} className={HeaderS.icon} alt="Facebook icon" />
                        </a>

                        <a href="https://www.instagram.com/webprofessorpl/" target="_blank" rel='noopener'>
                            <img src={IgIco} className={HeaderS.icon} alt="Instagram icon" />
                        </a>

                        <a href="https://www.linkedin.com/company/webprofessorpl" target="_blank">
                            <img src={InIco} className={HeaderS.icon} alt="LinkedIn icon" />
                        </a>

                    </div>

                    <div className={HeaderS.contact_info}>

                        <a href="tel:+48739909544" rel='noopener'>
                            <img src={TelIco} className={HeaderS.tel_ico} alt="Telephone icon" />
                            739 909 544
                        </a>

                        <a href="mailto:kontakt@web-professor.pl" rel='noopener'>
                            <img src={MailIco} alt="Mail icon" />
                            kontakt@web-professor.pl
                        </a>

                    </div>

                </nav>

            </div>

        </header>
    )
}

export default Header;