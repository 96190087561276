import * as React from 'react';
import { Link } from "gatsby";

import * as footerS from './footer.module.scss';
import * as WrapperS from '../../assets/styles/wrapper.module.scss';

import Logo from '../../assets/images/Logo-purple.svg';
import TelIco from '../../assets/images/tel.svg';
import MailIco from '../../assets/images/mail.svg';
import FbIco from '../../assets/images/fb.svg';
import IgIco from '../../assets/images/ig.svg';
import InIco from '../../assets/images/in.svg';


const Footer = () => {

    return (
        <footer className={`${footerS.footer_wrapper} ${WrapperS.apply}`}>

            <div className={footerS.logo_tile}>
                <Link to='/' className={footerS.logo_link}>
                    <img src={Logo} alt="WebProfessor logo" />
                </Link>

                WebProfessor Sp. z o.o.<br/>
                NIP: 525 292 27 24
            </div>

            <div className={footerS.social_media}>
                <a href="https://fb.me/webprofessorPL" target="_blank" rel='noopener'>
                    <img src={FbIco} className={footerS.icon} alt="Facebook icon" />
                </a>

                <a href="https://www.instagram.com/webprofessorpl/" target="_blank" rel='noopener'>
                    <img src={IgIco} className={footerS.icon} alt="Instagram icon" />
                </a>

                <a href="https://www.linkedin.com/company/webprofessorpl" target="_blank" rel='noopener'>
                    <img src={InIco} className={footerS.icon} alt="LinkedIn icon" />
                </a>
            </div>

            <div className={footerS.contact_info}>
                <a href="tel:+48739909544" rel='noopener'>
                    <img src={TelIco} className={footerS.tel_ico} alt="Telephone icon" />
                    739 909 544
                </a>

                <a href="mailto:kontakt@web-professor.pl" rel='noopener'>
                    <img src={MailIco} alt="Mail icon" />
                    kontakt@web-professor.pl
                </a>
            </div>


            <div className={footerS.more_info}>

                <Link to='/regulamin' target='_blank'>
                    Polityka prywatności i cookies
                </Link>

            </div>

        </footer>
    )
}

export default Footer;