import React, { useState, useEffect } from 'react';

import '../assets/styles/global.scss';
import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import CookiesPopup from '../components/cookies-popup/cookies-popup';

import PopupCta from '../components/popup-cta/popup-cta';

import * as MainLayoutS from './main-layout.module.scss';


export const CTAPopupOpenContext = React.createContext({
    active: false,
    setActive: () => {}
});

const MainLayout = ({ children }) => {

    const [popupActive, setPopupActive] = useState(false);


    useEffect(() => {

        window.fbAsyncInit = function() {
            window.FB.init({
              xfbml            : true,
              version          : 'v12.0'
            });
        };

        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

    }, [])

    return (
        <React.Fragment>

            <div id="fb-root"></div>

            <div id="fb-customer-chat"
                className="fb-customerchat"
                attribution="biz_inbox"
                page_id="100872668102427">
            </div>

            <CTAPopupOpenContext.Provider value={{popupActive, setPopupActive}}>

                <PopupCta />

                <CookiesPopup />

                <Header />

                <main>

                    { children }

                </main>

                <Footer />

            </CTAPopupOpenContext.Provider>

        </React.Fragment>
    );

}

export default MainLayout;